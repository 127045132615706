import {load} from 'recaptcha-v3';

export default function captchaForm() {
    if (!window.config) {
        return;
    }

    const SITE_KEY = window.config.googleRecaptchaSiteKey;
    const forms = document.querySelectorAll('.js-captcha-form');
    if (!forms || !SITE_KEY) {
        return;
    }

    load(SITE_KEY, {autoHideBadge: true}).then((recaptcha) => {
        forms.forEach((form) => {
            form.addEventListener('submit', (event) => {
                event.preventDefault();

                const {action} = form.dataset;

                recaptcha.execute(action).then((token) => {
                    /* global dataLayer */
                    window.dataLayer = window.dataLayer || [];

                    form.insertAdjacentHTML(
                        'afterbegin',
                        `<input type="hidden" name="token" value="${token}">`,
                    );

                    if (form.checkValidity()) {
                        dataLayer.push({
                            event: 'generate_Lead',
                            formName: form.dataset.formName,
                        });
                    }

                    form.submit();
                });
            });
        });
    });
}

window.addEventListener('load', captchaForm);
